@import '@genetec/gelato-react/dist/index.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-day-picker/lib/style.css';
@import './mixins.scss';
@import './sidePanel.scss';
@import './calendar.scss';
@import './unitList.scss';
@import './unit-info.scss';

.primary {
  color: #00aeef;
}

.container {
  background-color: rgba(0, 0, 0, 0.25098);
  padding: 15px;
}

#image-request-modal {
  > * {
    width: 548px;
  }

  gen-modal-body {
    > * {
      padding-top: 10px;
    }
  }
}

.error-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}

.error-tooltip {
  width: 540px;
  padding: 10px 15px;
  > div {
    display: grid;
    grid-template-columns: auto min-content;
    margin: 5px 0px;
  }
}

#login-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gen-card {
    height: 100%;
    flex-grow: 0;
    margin-right: auto;
    margin-left: auto;

    gen-card-body {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;

      gen-text {
        margin-bottom: 1em;
      }
    }
  }
}

.fail-reason-container {
  max-width: 630px;
}

#image-request-modal-form {
  a {
    text-decoration: none;
  }
}

.image-request-modal-allinone-content {
  display: flex;
  justify-content: space-between;
}

.image-request-modal-allinone-content-scmt-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  > a {
    margin-left: 10px;
  }
}

.image-request-modal-allinone-content gen-text {
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: $screen-lg-min) and (max-width: ($screen-xl-min - 1px)) {
  .fail-reason-container {
    max-width: 375px;
  }

  #unit-info-container {
    padding: 15px;
    row-gap: 1.5em;
    .first-row {
      column-gap: 1em;
      grid-template-columns: repeat(2, max-content) repeat(3, min-content) auto;
      .unit-id {
        column-gap: 1em;
      }
    }
    .third-row {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: repeat(12, 1fr);
      grid-auto-flow: revert;
      row-gap: 0.5em;
      column-gap: revert;
      > div:last-of-type {
        border-bottom: revert;
        box-shadow: revert;
      }
    }
  }
}

.desktop-hidden {
  @media (min-width: $screen-xl-min) {
    display: none;
  }
}

.tablet-hidden {
  @media (max-width: ($screen-xl-min - 1px)) {
    display: none;
  }
}

.scrollable-y {
  overflow-y: auto;
}

.list-label-padding-right {
  margin-right: 8px;
}

gen-text {
  user-select: text;
}
